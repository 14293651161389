import * as React from "react"
import FourCols from "../components/FourCols"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Navigation from "../components/Navigation"
import TwoCols from "../components/TwoCols"
import mainImage from "../images/main-image.png"
import roadmap1 from "../images/roadmap1.png"
import roadmap2 from "../images/roadmap2.png"
import roadmap3 from "../images/roadmap3.png"
import teamMember from "../images/team.png"
import faqMan from "../images/faq.png"
import leftArrow from "../images/arrow-left.png"
import rightArrow from "../images/arrow-right.png"
import line1 from "../images/line1.png"
import line2 from "../images/line2.png"
import line3 from "../images/line3.png"
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"
import FAQs from "../components/FAQs"
import DiamondHands from "../components/DiamondHands"
const PrivacyPage = () => (
  
   <Layout>
    
   <Container className="legal-container mb-10">
        <Row>
          <Col xs={12} className="text-container">
            
            <h1>
              Privacy Policy
            </h1>
            <p>
            This Privacy Policy ("Policy") describes the policies and procedures of Paper Labs LLC Studios Ltd. ("Paper Labs LLC", "we", “We”, “Us”, or "us") in relation to the storage, use, processing, and disclosure of personal information that those who are individuals, or the company, or other legal entity on behalf of which such individuals ("you" or "your") have chosen to share with us when you access PaperHands collection at our website paperhands.game (the “Site”) or any pages or websites under PaperHands collection brand operated by us or whenever we otherwise deal with you. This Policy, together with our Terms, applies to your use of the Site.<br /><br />

Paper Labs LLC is committed ensuring that your personal information is protected. Please read this Policy carefully to understand our practices regarding your personal information and how we handle your personal information. The Policy sets out the basis on which any personal information that we collect from you, or that you provide to us, will be processed by us.<br /><br />

No Collection of Personal Information<br /><br />

We do not collect and the Site does not collect any personal information about you, and we do not want to receive any personal information about you.  
<br /><br />
No Use of Personal Information<br /><br />

We do not use any personal information about you.  If you email us for support, for a privacy inquiry, or for any other feedback, we will respond to or otherwise handle your e-mail, but only to reply to the concerns, inquiries, or suggestions raised.  We will never use your e-mail address or personal information included in any e-mail that you send to us (such as, for example your name and address) for any other purpose.<br /><br />

No Disclosure of Personal Information<br /><br />

We do not store your personal information.  As such, we keep no personal information that we can share with or disclose to others.  We will not disclose your personal information to any third party, except with your consent or where required by law.<br /><br />

Privacy Contact<br /><br />

If you have any queries, requests or complaints relating to the handling of your personal information, please contact us by email.<br /><br />

Email: <a href="mailto:admin@paperhands.game">admin@paperhands.game</a>

            </p>
          </Col>

        </Row>
      </Container>

</Layout>
)

export default PrivacyPage
